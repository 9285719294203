<template>
    <b-overlay :show="show" rounded="sm">
        <b-card-code no-body title="Update professional">
            <b-row class="pb-2">
                <b-col md="6">
                    <div class="px-2">
                        <!-- form -->
                        <validation-observer ref="professionalUpdateForm">
                            <b-form
                            class="auth-register-form mt-2"
                            @submit.prevent="updateProfessional"
                            >
                                <!-- First name -->
                                <b-form-group>
                                    <label>First name</label>
                                    <validation-provider
                                    #default="{ errors }"
                                    name="First name"
                                    vid="firstname"
                                    rules="required"
                                    >
                                    <b-form-input
                                        id="first_name"
                                        v-model="firstName"
                                        name="first_name"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="First Name"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- Last name -->
                                <b-form-group>
                                    <label>Last name</label>
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Last name"
                                    vid="lastname"
                                    rules="required"
                                    >
                                    <b-form-input
                                        id="last_name"
                                        v-model="lastName"
                                        name="last_name"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Last Name"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- Qualifications -->
                                <b-form-group>
                                    <label>Qualifications</label>
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Qualifications"
                                    vid="qualifications"
                                    rules="required"
                                    >
                                    <b-form-input
                                        id="qualifications"
                                        v-model="qualifications"
                                        name="qualifications"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Qualifications"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- Description -->
                                <label>Description</label>
                                <b-form-textarea
                                    v-model="profile_description"
                                    placeholder="Description"
                                    rows="3"
                                />

                                <!-- Image -->
                                <div class="mt-1">
                                    <label>Image</label>
                                    <validation-provider
                                        v-slot="{ validate, errors }"
                                        ref="provider"
                                        rules="size:4096"
                                        name="image file"
                                    >
                                        <b-form-file
                                        accept=".jpg, .png, .jpeg"
                                        placeholder="Choose an image or drop it here..."
                                        drop-placeholder="Drop file here..."
                                        @change="handleFileChange($event) || validate($event)"
                                        />
                                        <div>
                                            <small class="color-gray">Allowed formats: .jpg, .jpeg and .png</small>
                                        </div>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="mt-1">
                                    <b-img
                                        fluid
                                        :src="profile_image_url"
                                        class="image-preview"
                                    />
                                </div>

                                <b-button
                                    class="mt-2"
                                    variant="primary"
                                    type="submit"
                                >
                                    Update professional
                                </b-button>
                            </b-form>
                        </validation-observer>
                    </div>
                </b-col>
            </b-row>
        </b-card-code>
    </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BButton, BFormInput, BFormTextarea, BForm, BFormFile, BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend, BOverlay, BImg } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BButton,
        BForm,
        BFormFile,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BSpinner,
        BOverlay,
        BImg
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            qualifications: "",
            profile_description: "",
            profile_image: [],
            profile_image_url: "",

            // validation
            required,

            show: true,
            errors: [],
        }
    },
    methods: {
        async handleFileChange(e) {
            if (
                e.target.files[0].type !== "image/jpeg" &&
                e.target.files[0].type !== "image/png"
            ) {
                e.target.value = [];
                this.$refs.provider.applyResult({
                errors: ["Image must be in .jpg, .jpeg or .png format"], // array of string errors
                valid: false, // boolean state
                failedRules: {}, // should be empty since this is a manual error.
                });
            } else {
                const { valid } = await this.$refs.provider.validate(e);
                if (valid) {
                // Set image file
                this.profile_image = e.target.files[0]

                // Create base64 from file - connected with profile_image_url
                this.createImage(e.target.files[0]);
                }
            }
        },

        createImage(file) {
            let reader = new FileReader();
            let vm = this;

            reader.onload = (e) => {
                vm.profile_image_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        // Request to update professional
        updateProfessional() {
            this.show = true

            let professional = new FormData();
            professional.append("first_name", this.firstName);
            professional.append("last_name", this.lastName);
            professional.append("qualifications", this.qualifications);
            professional.append("profile_description", this.profile_description);
            // If image added to field
            if (this.profile_image != undefined && this.profile_image !== []) {
                professional.append("profile_image", this.profile_image);
            }
                professional.append("_method", "patch");

            this.$refs.professionalUpdateForm.validate().then(success => {
                if (success) {
                    let id = this.$route.params.id

                    this.$http.post("/api/auth/admin/professionals/" + id, professional, {
                        onUploadProgress: (uploadEvent) => {
                            this.uploadCompletedPercentage = Math.round(
                            (uploadEvent.loaded / uploadEvent.total) * 100
                            );
                        },
                    })
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Professional successfully updated",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        this.show = false
                    })
                    .catch((error) => {
                        this.show = false
                        
                        let errorMsg = "There was an error!"

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMsg,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            });
        },
    },
    mounted() {
        let id = this.$route.params.id
        
        //Return professional data
        this.$http.get("/api/auth/admin/professionals/" + id)
            .then((res) => {
                this.firstName = res.data.first_name,
                this.lastName = res.data.last_name,
                this.qualifications = res.data.qualifications,
                this.profile_description = res.data.profile_description
                this.profile_image_url = res.data.profile_image_url

                // Hide overlay
                this.show = false
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });

                // Hide overlay
                this.show = false
            })
    }
}
</script>

<style scoped>
.image-preview {
    max-width: 200px;
}
</style>